.post h1 {
  font-size: 2.5em;
  margin: 0;
}

.post {
  main {
    .wrapper {
      max-width: 800px;
      width: 100%;
    }
    h1, h2, h3, h4 {
      color: #ff3399;
      text-align: left;
    }
    code.language-plaintext {
      color: var(--color-code);
    }
  }
}

.blog .card h4 {
  color: #ff3399;
}

.post-content {
  a {
    color: #ff3399;
  }
  a:hover,
  a:active {
    text-shadow: -0.5px 0 2px #ff3399, -0.5px 0 6px #ff3399;
  }
  img {
    display: block;
    margin: 10px auto;
  }
}


.post-list {
  padding: 20px 0;
  display: flex;
  justify-content: space-evenly;
}