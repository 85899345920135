$base-font-family: "Roboto Condensed", Roboto, -apple-system, BlinkMacSystemFont,
  "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #111 !default;
$background-color: #fdfdfd !default;
$brand-color: #2a7ae2 !default;

$grey-color: #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 1200px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #aaa;
  font-family: sans-serif;
  font-family: $base-font-family;
  font-weight: normal;
  margin: 0;
  word-wrap: break-word;
  background-color: #111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}

.cyan {
  color: #1ffff0;
}
a.cyan.active,
a.cyan:hover,
a.cyan:active {
  text-shadow: -0.5px 0 2px #1ffff0, -0.5px 0 6px #1ffff0;
}

.pink {
  color: #ff3399;
}
a.pink.active,
a.pink:hover,
a.pink:active {
  text-shadow: -0.5px 0 2px #ff3399, -0.5px 0 6px #ff3399;
}

.yellow {
  color: #fff01f;
}

a.yellow.active,
a.yellow:hover,
a.yellow:active {
  text-shadow: -0.5px 0 2px #fff01f, -0.5px 0 6px #fff01f;
}

.button {
  text-decoration: none;
  border: 1px solid #fff;
  padding: 5px 30px;
  font-size: 1.5em;
  transition: all 0.3s ease-out;
  &:hover {
    box-shadow: 0 0 2px #fff, 0 0 6px #fff;
  }
}

.button.cyan {
  border-color: #1ffff0;
  &:hover {
    text-shadow: none;
    box-shadow: 0 0 2px #1ffff0, 0 0 6px #1ffff0;
  }
}

.icon-button {
  display: flex;
  justify-content: center;
  i {
    display: flex;
    width: 30px;
    margin-right: 10px;
  }
}
.icon-only {
  padding: 20px;
  border-radius: 50%;
  i {
    margin: 0;
  }
}
.center {
  margin: 0 auto;
}

h2,
h3 {
  text-align: center;
}

.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  li {
    margin: 10px 20px;
  }

}

.card {
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #222;
  box-shadow: 0 -2px 4px rgba(255, 51, 153, 0.7),
    0 2px 4px rgba(255, 51, 153, 0.7);
  transition: all 0.1s ease-out;
  will-change: translate, box-shadow;
  &:hover,
  &:active {
    transform: translateY(-5px);
    top: 4px;
    box-shadow: 0 10px 25px rgba(255, 51, 153, 0.4);
  }
  &.yellow {
    box-shadow: 0 -2px 4px rgba(255, 240, 31, 0.7),
    0 2px 4px rgba(255, 240, 31, 0.7);
    &:hover, &:active {
      box-shadow: 0 10px 25px rgba(255, 240, 31, 0.4);
    }
  }
  .top {
    width: 300px;
    height: 200px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    filter: brightness(1.1)
  }
  .bottom {
    height: 150px;
    position: relative;
    overflow: hidden;
    padding: 10px 20px 30px;
    color: var(--color-text);
  }
  p {
    margin: 0;
  }
  img {
    object-fit: cover;
    object-position: center;
    max-height: 80%;
    max-width: 90%;
    width: auto;
    opacity: 1;
  }
  h4 {
    margin: 0;
    font-size: 1.4em;
  }
  .subtitle {
    font-size: 0.8em;
    color: #777;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}
