header .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

header nav {
  margin: 20px 0;
}

.logo svg {
  animation: tronFilter 8s infinite;
  fill: #000000;
  stroke: #000;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: bevel;
}

.about .logo svg {
  animation: tronCyan 4s infinite;
}

.blog .logo svg,
.post .logo svg {
  animation: tronPink 4s infinite;
}

.lab .logo svg {
  animation: tronYellow 4s infinite;
}

header nav a {
  margin: 0 10px;
  font-size: 25px;
  transition: all 0.3s ease-in;
}

header nav a.active {
  text-decoration: none;
}
