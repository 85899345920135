/* Landscape phone and beyond */
@media (min-width: 430px) {
    footer nav {
      flex-direction: row;
      margin: 25px auto 0;
    }
  
    footer nav a.icon-button {
      width: initial;
      margin-bottom: 0;
    }

    .client-list ul li {
      width: 15%;
      margin: 0 2% 20px;
    }
  }
