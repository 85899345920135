@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/RobotoCondensed-Regular.ttf");
}

h2.about {
  font-size: 2.5em;
  margin: 10px 0;
}

h2.clients {
  margin: 0 0 30px;
}