@keyframes tronFilter {
    0% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      stroke: #1ffff0;
      -webkit-text-stroke-color: #1ffff0;
    }
  
    30% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #ff3399);
      filter: drop-shadow(-0.75px 0px 6px #ff3399);
      stroke: #ff3399;
      -webkit-text-stroke-color: #ff3399;
    }
  
    60% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #fff01f);
      filter: drop-shadow(-0.75px 0px 6px #fff01f);
      stroke: #fff01f;
      -webkit-text-stroke-color: #fff01f;
    }
  
    100% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      stroke: #1ffff0;
      -webkit-text-stroke-color: #1ffff0;
    }
  }
  
  @keyframes tronCyan {
    0% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      stroke: #1ffff0;
      -webkit-text-stroke-color: #1ffff0;
    }
  
    50% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #16b3a8);
      filter: drop-shadow(-0.75px 0px 6px #16b3a8);
      stroke: #16b3a8;
      -webkit-text-stroke-color: #16b3a8;
    }
  
    100% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      filter: drop-shadow(-0.75px 0px 6px #1ffff0);
      stroke: #1ffff0;
      -webkit-text-stroke-color: #1ffff0;
    }
  }
  
  @keyframes tronPink {
    0% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #ff3399);
      filter: drop-shadow(-0.75px 0px 6px #ff3399);
      stroke: #ff3399;
      -webkit-text-stroke-color: #ff3399;
    }
  
    50% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #cc297a);
      filter: drop-shadow(-0.75px 0px 6px #cc297a);
      stroke: #cc297a;
      -webkit-text-stroke-color: #cc297a;
    }
  
    100% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #ff3399);
      filter: drop-shadow(-0.75px 0px 6px #ff3399);
      stroke: #ff3399;
      -webkit-text-stroke-color: #ff3399;
    }
  }
  
  @keyframes tronYellow {
    0% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #fff01f);
      filter: drop-shadow(-0.75px 0px 6px #fff01f);
      stroke: #fff01f;
      -webkit-text-stroke-color: #fff01f;
    }
  
    50% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #b3a816);
      filter: drop-shadow(-0.75px 0px 6px #b3a816);
      stroke: #b3a816;
      -webkit-text-stroke-color: #b3a816;
    }
  
    100% {
      -webkit-filter: drop-shadow(-0.75px 0px 6px #fff01f);
      filter: drop-shadow(-0.75px 0px 6px #fff01f);
      stroke: #fff01f;
      -webkit-text-stroke-color: #fff01f;
    }
  }