footer {
  margin: 40px auto;
  width: 100%;
}

footer nav {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--size-content);
  padding: 0 var(--size-gutter);
  margin: 0 auto 0;
}

footer nav a.icon-button {
  color: var(--color-text);
  border-color: var(--color-text);
  opacity: 0.7;
  transition: all 0.5s;
  margin: 0 15px;
}

footer nav a:hover,
footer nav a:active {
  opacity: 1;
  color: #fff;
  border-color: #fff;
}
