.skill-list {
  display: flex;
  flex-direction: column;
}

.skill-list ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 30px auto;
  width: fit-content;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.skill-list ul li {
  margin: 10px;
  // width: 50px;
}

.skill-list ul li svg {
  height: 40px;
  max-width: 50px;
}

.skill-list.short ul li svg {
  opacity: 0.6;
}

.about-box {
  text-align: center;
}

.about-box section {
  font-weight: 100;
  font-size: 3em;
  font-size: 2.5em;
}

.client-list {
  padding: 0 2%;
}

.client-list ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.client-list ul li {
  width: 28%;
  margin-bottom: 5%;
}

.client-list img {
  width: 100%;
  height: auto;
  /* margin: 0 5px 20px; */
}
